import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

// import characterThumb from "../../../../assets/images/nft/Character1.png";
import coin2 from "../../../../assets/images/nft/coin2.png";
import mintLiveDownArrow from "../../../../assets/images/tweet.png";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import { useState } from "react";
// import { totalMintCount } from "../../../../utils/web3mint";
import { useAccount, useSendTransaction } from "wagmi";

import { useNavigate } from "react-router-dom";

const Banner = ({ transferTokens }) => {
  const { isPending } = useSendTransaction();

  const navigate = useNavigate();

  const { mintModalHandle, connectWalletModalHanlde, walletModalHandle } =
    useModal();
  const [remaining] = useState(18);

  const account = useAccount();

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h2>Swap to Earn 🔁 </h2>
              <h3>
                Earn{" "}
                <span className="count">
                  <Counter end={remaining} duration={3} />
                </span>
                % on every swap
              </h3>
              <div className="banner_buttons">
                {account.address ? (
                  <Button lg variant="mint" onClick={() => transferTokens()}>
                    {" "}
                    {isPending ? " please wait..." : "Swap now"}
                  </Button>
                ) : (
                  <Button lg variant="mint" onClick={() => walletModalHandle()}>
                    {" "}
                    Swap now
                  </Button>
                )}

                <Button lg variant="outline" onClick={() => navigate("/help")}>
                  issues swapping?
                </Button>
              </div>
              <div className="coin-info">
                <span>Get rewarded for swapping onchain</span>
                <span>Max 1 Swap per wallet . allow gas fees</span>
                <span>
                  SWAP TO EARN IS LIVE{" "}
                  <span className="highlighted">UNTIL 30 SEPT 04:00H</span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="">
                  <div className="">
                    {/* <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span> */}
                    {/* <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span> */}
                  </div>
                </div>

                {/* <div className="bithu_v1_baner_right_img_bg">
                  <img src={mintLiveDownArrow} alt="" />
                </div> */}
                <div className="bithu_v1_baner_right_img">
                  <img src={mintLiveDownArrow} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
