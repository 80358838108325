const data = [
  {
    num: "1",
    mintColor: "green",
    text: "Connect Your Wallet",
  },
  {
    num: "2",
    mintColor: "blue",
    text: "Click on 'CLAIM NOW'",
  },
  {
    num: "3",
    mintColor: "yellow",
    text: "Confirm the Transaction",
  },
  {
    num: "4",
    mintColor: "red",
    text: "Receive Your Tokens",
  },
];

export default data;
