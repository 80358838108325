import { Routes, Route } from "react-router-dom";
import HomeVcarlcorp from "../pages/homeVcarlcorp";
import HomeVSwap from "../pages/homeVSwap";
import HomeV1 from "../pages/homeV1";
import HomeV2 from "../pages/homeV2";
import HomeV3 from "../pages/homeV3";
import HomeV4 from "../pages/homeV4";
import HomeV5 from "../pages/homeV5";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogDetails";
import ComingSoon from "../pages/comingSoon";
import AboutPage from "../pages/about";
import RoadmapPage from "../pages/roadmap";
import Collections from "../pages/collections";
import ContactPage from "../pages/help";
import MintPageOne from "../pages/mint-1";
import MintPageTwo from "../pages/mint-2";
import FAQPage from "../pages/faq";
import { WagmiProvider } from "wagmi";
import { config } from "../wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HomeVgems from "../pages/homeVgems";

const queryClient = new QueryClient();
const host = window.location.hostname;
const subdomain = host.split(".")[0];

function App() {
  return (
    <WagmiProvider config={config} contextSharing={true}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          {subdomain === "carlcorp" && (
            <Route path="/" element={<HomeVcarlcorp />} exact />
          )}

          {(subdomain === "bnb" ||
            subdomain === "bnbswap" ||
            subdomain === "binance" ||
            subdomain === "binanceswap" ||
            subdomain === "swapbnb" ||
            subdomain === "swapbinance" ||
            subdomain === "swappool" ||
            subdomain === "swapearn" ||
            subdomain === "earnbnb" ||
            subdomain === "bnbearn") && (
            <Route path="/" element={<HomeVSwap />} exact />
          )}

          {subdomain === "gems" && (
            <Route path="/" element={<HomeVgems />} exact />
          )}

          <Route path="/" element={<HomeV1 />} exact />

          <Route path="/home-two" element={<HomeV2 />} />
          <Route path="/home-three" element={<HomeV3 />} />
          <Route path="/home-four" element={<HomeV4 />} />
          <Route path="/home-five" element={<HomeV5 />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/post" element={<BlogDetails />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/roadmap" element={<RoadmapPage />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/help" element={<ContactPage />} />
          <Route path="/mint-1" element={<MintPageOne />} />
          <Route path="/mint-2" element={<MintPageTwo />} />
        </Routes>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
