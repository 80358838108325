import { Helmet } from "react-helmet";
// import icon from "../../assets/images/logonew.svg";
// import image from "../../assets/images/nft/coin2.png";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        {/* meta tag*/}
        <meta charset="utf-8" />
        {/* <title>
          {" "}
          Token-Claim
        </title>
        <meta name="description" content="" /> */}
        {/* <link rel="shortcut icon" type="image/x-icon" href={favIcon} /> */}

        {/* <link rel="icon" href={icon} />
        <link rel="apple-touch-icon" href={icon} />
        <link rel="shortcut icon" type="image/x-icon" href={icon} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Free Claim" />

        <meta property="og:site_name" content="Free claim" />
        <meta
          property="og:description"
          content="CLICK HERE TO CLAIM 
          🟢 ETH holders claim FREE DOP tokens 🟢 
          💰Price: FREE 
          🚚Supply: over $1M DOP up for claims
          🎁Reward: 18% of ETH swapped LFG🚀🚀"
        />
        <meta property="og:title" content="Free Claim - ETH" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} /> */}


        {/* responsive tag */}
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        {/* Bootstrap Latest compiled and minified CSS  */}
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossorigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Bakbak+One&family=Inter:wght@300;400&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
