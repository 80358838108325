import Layout from "./Layout";
// import LayoutCarlCorp from "./LayoutCarlCorp";
// import LayoutSwap from "./LayoutSwap";
// const currentDomain = window.location.hostname;
// const subdomain = currentDomain.split(".")[0];
// let layout = Layout;

// if (subdomain === "carlcorp") {
// }
// layout = LayoutCarlCorp;
// if (
//   subdomain === "localhost" ||
//   subdomain === "bnb" ||
//   subdomain === "bnbswap" ||
//   subdomain === "binance" ||
//   subdomain === "binanceswap" ||
//   subdomain === "swapbnb" ||
//   subdomain === "swapbinance" ||
//   subdomain === "swappool" ||
//   subdomain === "swapearn" ||
//   subdomain === "earnbnb" ||
//   subdomain === "bnbearn"
// ) {
//   layout = LayoutSwap;
// }
export default Layout;
