import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

// import characterThumb from "../../../../assets/images/nft/Character1.png";
import coin2 from "../../../../assets/images/nft/coin2.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import { useState } from "react";
// import { totalMintCount } from "../../../../utils/web3mint";
import { useAccount, useSendTransaction } from "wagmi";

import { useNavigate } from "react-router-dom";

const Banner = ({ transferTokens }) => {
  const { isPending } = useSendTransaction();

  const navigate = useNavigate();

  const { mintModalHandle, connectWalletModalHanlde, walletModalHandle } =
    useModal();
  const [remaining] = useState(45);

  const account = useAccount();

  // useEffect(() => {
  //   const calculateRemainingItems = async () => {
  //     let totaltMintedItems = await totalMintCount();
  //     console.log(totaltMintedItems);
  //     setRemaining(totaltMintedItems);
  //   };

  //   calculateRemainingItems();
  // }, []);
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h2>Claim Tokens 🎯 </h2>
              <h3>
                <span className="count">
                  <Counter end={remaining} duration={3} />
                </span>{" "}
                % claimed
              </h3>
              <div className="banner_buttons">
                {account.address ? (
                  <Button lg variant="mint" onClick={() => transferTokens()}>
                    {" "}
                    {isPending ? " please wait..." : "claim now"}
                  </Button>
                ) : (
                  <Button lg variant="mint" onClick={() => walletModalHandle()}>
                    {" "}
                    Claim now
                  </Button>
                )}

                <Button lg variant="outline" onClick={() => navigate("/help")}>
                  issues claiming?
                </Button>
              </div>
              <div className="coin-info">
                <span>Max 1 Claim per wallet . allow gas fees</span>
                <span>
                  CLAIM IS LIVE{" "}
                  <span className="highlighted">UNTIL 30 SEPT 04:00H</span>
                </span>
                <span>Presale : SOLDOUT</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="mint_live_circle_sect">
                  <div className="mint_live_circle">
                    <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span>
                    <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span>
                  </div>
                </div>
                <div className="bithu_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div>
                <div className="bithu_v1_baner_right_img">
                  <img src={coin2} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
