import { http, createConfig } from "wagmi";
import {
  mainnet,
  sepolia,
  bsc,
  polygon,
  avalanche,
  bscTestnet,
  celo,
  goerli,
  cronos,
} from "wagmi/chains";
import { coinbaseWallet, injected, walletConnect } from "wagmi/connectors";
import { Buffer } from "buffer";

// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

export const config = createConfig({
  chains: [
    mainnet,
    sepolia,
    bsc,
    polygon,
    avalanche,
    bscTestnet,
    celo,
    goerli,
    cronos,
  ],
  connectors: [
    injected(),
    coinbaseWallet({ appName: "Claim token" }),
    walletConnect({
      projectId: process.env.REACT_APP_WC_PROJECT_ID || "",
      qrModalOptions: {
        themeVariables: {
          "--wcm-z-index": "9999999",
        },
      },
    }),
  ],
  ssr: true,
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [bsc.id]: http(),
    [polygon.id]: http(),
    [avalanche.id]: http(),
    [bscTestnet.id]: http(),
    [celo.id]: http(),
    [goerli.id]: http(),
    [cronos.id]: http(),
  },
});

// declare module "wagmi" {
//   interface Register {
//     config: typeof config;
//   }
// }
